<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  @import './assets/base.css';
</style>
